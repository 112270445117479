var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      staticClass: "mb-0",
      attrs: { title: "Dropdown menu support" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDropdown) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Add ")]),
        _c("code", [_vm._v("<b-dropdown>")]),
        _c("span", [_vm._v(" menus directly inside your ")]),
        _c("code", [_vm._v("<b-button-group>")]),
        _c("span", [
          _vm._v(
            ". Note that split dropdown menus are not supported when prop "
          )
        ]),
        _c("code", [_vm._v("vertical")]),
        _c("span", [_vm._v(" is set.")])
      ]),
      _c(
        "b-button-group",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Button ")]
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { right: "", variant: "outline-primary", text: "Menu" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Item 1")]),
              _c("b-dropdown-item", [_vm._v("Item 2")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Item 3")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                variant: "outline-primary",
                right: "",
                split: "",
                text: "Split Menu"
              }
            },
            [
              _c("b-dropdown-item", [_vm._v("Item 1")]),
              _c("b-dropdown-item", [_vm._v("Item 2")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Item 3")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }